import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql, Link } from "gatsby";
import { FaUsers, FaHistory, FaDownload, FaExchangeAlt } from "react-icons/fa";
import BenefitBlock from "../components/benefitBlock";
import SEO from "../components/seo";
import Lightbox from "../components/lightBox";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import TryButton from "../components/TryButton";

export const query = graphql`
  query {
    kart1: file(
      relativePath: { eq: "Kartoteka-kontrahenta-w-prostym-systemie-CRM.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1347, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    kart2: file(
      relativePath: { eq: "aktywnosci-w-kartotece-kontrahenta.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1347, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Kartoteki = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Baza wiedzy o kontrahentach"
      headline="Buduj relacje na podstawie wiedzy"
    >
      <SEO
        title="Kartoteki kontrahentów - baza informacji o klientach ✍"
        description="Kartoteki kontrahentów w CRM • Historia kontaktów • Ważne dane w jednym miejscu • Spójna baza wiedzy o klientach • Wypróbuj QuickCRM: 30 dni za darmo!"
        keywords={["kartoteki kontrahentów"]}
      />
      <p>
        <strong>
          Baza wiedzy o&nbsp;kontrahentach w&nbsp;QuickCRM jest uporządkowanym
          zbiorem najważniejszych informacji o&nbsp;Twoich klientach.
        </strong>
      </p>
      <p>
        W&nbsp;kartotece kontrahenta znajdziesz podstawowe informacje
        o&nbsp;firmie, takie jak nazwa, opiekun, NIP, a&nbsp;także dane
        kontaktowe do poszczególnych osób. W&nbsp;tym miejscu znajduje się
        również cała&nbsp;<strong>historia kontaktów z&nbsp;klientem </strong>
        oraz&nbsp;wszystkie aktywne i&nbsp;zrealizowane&nbsp;
        <Link to="/kalendarz-i-zadania/">zadania</Link>. Nie poświęcaj czasu na
        uzupełnianie informacji o&nbsp;nowym kliencie. Wystarczy, że wpiszesz
        jego NIP, a&nbsp;dane z&nbsp;GUS pojawią się automatycznie.
      </p>
      <br />
      <br />
    
       <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto"
        }}
        images={[data.kart1, data.kart2]}
        alts={["kartoteki kontrahentów, karteteki kontrahentów w systemie crm"]}
      />
                                    <br />
            <br />
      <p>
        <strong>
          Wszystkie dane, dotyczące&nbsp;kontaktów Twojego handlowca
          z&nbsp;klientem są przechowywane w&nbsp;systemie.
        </strong>{" "}
        W&nbsp;przypadku nieobecności pracownika będziesz mógł przekazać obsługę
        danego klienta innej osobie. Ta bez problemu sprawdzi informacje na
        temat relacji z&nbsp;tym kontrahentem. Zobaczy zaplanowane
        i&nbsp;wykonane zadania, historię kontaktów i&nbsp;dzięki temu
        skutecznie przejmie inicjatywę sprzedażową.
      </p>
      <p>
        Zapomnij o&nbsp;kalendarzach, Excelu i&nbsp;innych prowizorycznych
        rozwiązaniach, które potęgują jedynie chaos informacyjny w&nbsp;Twojej
        firmie.
      </p>
      <p>
        <strong>
          Baza wiedzy o&nbsp;kontrahentach w&nbsp;QuickCRM pozwala Ci zarządzać
          informacjami w&nbsp;łatwy, bezpieczny i&nbsp;skuteczny sposób.
        </strong>
      </p>
      <h3
        style={{
          textAlign: "center"
        }}
      >
      <br />
      <br />
        <strong>Co zyskujesz?</strong>
      </h3>
      <BenefitBlockWrapper>
        <BenefitBlock
          icon={<FaUsers size="45px" />}
          content="Uporządkowaną i spójną bazę wiedzy o klientach"
        />
        <BenefitBlock
          icon={<FaHistory size="45px" />}
          content="Pełną historię kontaktów z klientem"
        />
        <BenefitBlock
          icon={<FaDownload size="45px" />}
          content="Czas, dzięki automatycznemu pobieraniu danych z GUS"
        />
        <BenefitBlock
          icon={<FaExchangeAlt size="45px" />}
          content="Możliwość szybkiego przejęcia tematu sprzedażowego"
        />
      </BenefitBlockWrapper>
    </FunctionPageLayout>
  );
};

export default Kartoteki;
